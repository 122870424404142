import common from "@/common/mixins/common.js";



export default {
  name: "notice",
  components: {
    
  },
  mixins: [common],
  data() {
    return {
      tableData: [],
      pageInfo: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
      searchform: {
        //查询
        Name: "",
        ItemNo: "",
        GtClsNo: "",
        InLang: "",
        Lxys: "",
        IsBiNew:""
      },
      goodsType: [], //商品类型
      options: [
        {
          value: "en",
          label: "国际仓",
        },
        {
          value: "cn",
          label: "国内仓",
        },
      ],
      IsBiNewOptions: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
    };
  },
  watch: {

    
  },
  created() {
    // this.getGoodsType()
    this.getTableData()
  },
  methods: {
        //获取商品类别
        // getGoodsType() {
        //   this.$axios.get("/a1/gt").then((res) => {
        //     let newGoodsType = [];
        //     let goodsType = res.data.data;
        //     for (let i = 0; i < goodsType.length; i++) {
        //       newGoodsType[goodsType[i].cls_no] = goodsType[i].cls_cn;
        //     }
        //     this.goodsType = newGoodsType;
        //   });
        // },
      //改变条数
      handleRenewRenewSizeChange(val) {
        this.pageInfo.pagesize=val;
        this.getTableData();
      }, //翻页
      handleChangeCurrentPage(val) {
        this.pageInfo.page=val;
        this.getTableData();
      },

      //过滤数据
      filterData(goodsDataArr) {
        for (let i in goodsDataArr) {
          if (goodsDataArr[i].file_type_icon_list) {
            goodsDataArr[i].file_type_icon_list_arr =
              goodsDataArr[i].file_type_icon_list.split(",");
          } else {
            goodsDataArr[i].file_type_icon_list_arr = [];
          }
        }
        return goodsDataArr;
      },

      // 获取列表数据
      getTableData(){
        let requestData = {
          page: this.pageInfo.page,
          pageSize: this.pageInfo.pagesize,
          Name: this.searchform.Name, //商品名字
          ItemNo: this.searchform.ItemNo, //条形编码
          GtClsNo: this.searchform.GtClsNo, //类型ID
          InLang: this.searchform.InLang, //仓库
          Lxys : this.searchform.Lxys,
          is_request_not_arrived:1, //是否请求未到商品清单
          IsBiNew :this.searchform.IsBiNew
        };
        this.$axios
          .get("/a1/goods", {
            params: requestData,
          })
          .then((response) => {
            const { data } = response.data;
            this.pageInfo.page = data.page;
            this.pageInfo.total = data.total;
            this.tableData = this.filterData(data.data);
            this.$forceUpdate();
          });
      },

      /* 导出 */
      exportNotArrive(){
        let requestData = {
          page: this.pageInfo.page,
          pageSize: this.pageInfo.pagesize,
          Name: this.searchform.Name, //商品名字
          ItemNo: this.searchform.ItemNo, //条形编码
          GtClsNo: this.searchform.GtClsNo, //类型ID
          InLang: this.searchform.InLang, //仓库
          Lxys : this.searchform.Lxys,
          is_request_not_arrived:1, //是否请求未到商品清单
          IsBiNew :this.searchform.IsBiNew
        };
        //创建订单商品导出
        this.$axios.post("/a1/goods/notarrive/export", requestData).then((res) => {
          let dowloadKey=res.data.data.to_key;
          //开始下载
          location.href=this.$root.DownUrl + "/c1/down/" + dowloadKey;
        });
      },

          //搜索
    searchEvn() {
      this.getTableData()
    },
    // 重置
    resetForm() {
      for (const key in this.searchform) {
        this.searchform[key] = ''
      }
      this.getTableData()
    }
    

    
  },
};
