<template>
    <div class="listdata">
      <div class="listdata-header">
        <el-form
            :inline="true"
            ref="form"
            :model="searchform"
            label-width="40px"
            class="form-wrap"
          >
            <el-form-item label="名称" class="form-item-wrap">
              <el-input
                v-model="searchform.Name"
                size="mini"
                clearable
                style="width: 170px"
                @keyup.native.enter="searchEvn"
              ></el-input>
            </el-form-item>
            <el-form-item label="条码" class="form-item-wrap">
              <el-input
                v-model="searchform.ItemNo"
                size="mini"
                clearable
                style="width: 170px"
                @keyup.native.enter="searchEvn"
              ></el-input>
            </el-form-item>
            <el-form-item label="类别" class="form-item-wrap">
              <el-input 
                v-model="searchform.GtClsNo"
                size="mini"
                clearable
                style="width: 120px"
                @keyup.native.enter="searchEvn"
              ></el-input>
            </el-form-item>
            <el-form-item label="仓库" class="form-item-wrap">
              <!-- <el-input v-model="searchform.warehouse" size="mini" clearable></el-input> -->
              <el-select
                v-model="searchform.InLang"
                placeholder="请选择"
                size="mini"
                clearable
                style="width: 120px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否新品" class="form-item-wrap">
              <!-- <el-input v-model="searchform.warehouse" size="mini" clearable></el-input> -->
              <el-select
                v-model="searchform.IsBiNew"
                placeholder="请选择"
                size="mini"
                clearable
                style="width: 120px"
              >
                <el-option
                  v-for="item in IsBiNewOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="流行" class="form-item-wrap">
              <el-input v-model="searchform.Lxys" size="mini" style="width:120px;" clearable></el-input>
            </el-form-item>
            
            <el-form-item class="form-item-wrap" style="margin-bottom: 6px">
              <el-button type="primary" size="mini" @click="searchEvn">搜索</el-button>
              <el-button type="warning" size="mini" @click="resetForm">重置</el-button>
              <el-button type="danger" size="mini" @click="exportNotArrive">导出</el-button>
            </el-form-item>
          </el-form>
      </div>
      <div class="listdata-content">
        <div class="listdata-content-list">
            <el-table
            border
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%; z-index: 99"
            height="630"
          >

          
            <el-table-column
              prop="id"
              label="ID"
              align="center"
              width="80"
            ></el-table-column>

            <el-table-column label="名称" align="center" width="300">
              <template slot-scope="scope">
                <div class="media" style="text-align: left">
                  <viewer>
                    <img
                      height="60"
                      :src="`http://hwimg.xmvogue.com/thumb/${scope.row.item_no}.jpg?x-oss-process=style/800`"
                      class="mr-3"
                      alt="..."
                    />
                  </viewer>
                  <div class="media-body">
                    <h6 class="mt-0" style="font-size: 14px">
                      {{ scope.row.item_name }}
                    </h6>
                    <p>{{ scope.row.item_en }}</p>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="item_no"
              align="center"
              label="货号"
            >
            </el-table-column>

            <el-table-column
              prop="xm_lxys"
              align="center"
              label="流行元素"
            >
            </el-table-column>

            <el-table-column
              prop="item_no"
              align="center"
              label="认证文件"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.file_type_icon_list_arr.length > 0">
                  <img
                    width="30"
                    :src="item"
                    v-for="(item, index) in scope.row.file_type_icon_list_arr"
                    :key="index"
                    alt=""
                  />
                </div>
                <div v-else>无认证</div>
              </template>
            </el-table-column>

            <el-table-column
              prop="item_no"
              align="center"
              label="商品类型"
            >
              <template slot-scope="scope">
                <table style="width: 198px">
                  <tr>
                    <td>编码：</td>
                    <td align="left">
                      <el-tag type="warning">{{ scope.row.gt_cls_no }}</el-tag>
                    </td>
                  </tr>
                  <tr>
                    <td>大类：</td>
                    <td align="left">
                      <el-tag type="danger"
                      >{{ scope.row.BTypeClsCn }}
                      </el-tag>
                    </td>
                  </tr>
                  <tr>
                    <td>中类：</td>
                    <td align="left">
                      <el-tag type="success"
                      >{{ scope.row.MTypeClsCn }}                      
                      </el-tag>
                    </td>
                  </tr>
                  <tr>
                    <td>小类：</td>
                    <td align="left">
                      <el-tag type="info"
                      >{{ scope.row.GtClsNoClsCn }}
                      </el-tag>
                    </td>
                  </tr>
                </table>
              </template>
            </el-table-column>

            <el-table-column width="100" align="center" label="价格">
              <template slot-scope="scope">
                <el-row :gutter="5">
                  <el-col :span="10" class="endalign fontsize15">
                    <span>进:</span>
                  </el-col>
                  <el-col :span="14" class="leftalign fontsize15">
                    {{ scope.row.base_price }}
                  </el-col>
                </el-row>
                <el-row :gutter="5">
                  <el-col :span="10" class="endalign fontsize15">
                    <span>售:</span>
                  </el-col>
                  <el-col :span="14" class="leftalign fontsize15">
                    {{ scope.row.sale_price }}
                  </el-col>
                </el-row>
<!--                <el-row>
                  <span v-if="scope.row.sale_price > scope.row.base_price" style="color: red">+ {{ (scope.row.sale_price * 1000 - scope.row.base_price * 1000)/1000 }}</span>
                  <span v-if="scope.row.sale_price < scope.row.base_price" style="color: green">- {{ (scope.row.base_price * 1000 - scope.row.sale_price * 1000)/1000 }}</span>
                </el-row>-->
              </template>
            </el-table-column>

            <el-table-column
              prop="stock_num"
              align="center"
              label="产品库存"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-row :gutter="5">
                  <el-col :span="16" class="endalign fontsize15">
                    <span>海鼎库存:</span>
                  </el-col>
                  <el-col :span="8" class="leftalign fontsize15">
                    <span class="text-color">{{ scope.row.stock_num }}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="5">
                  <el-col :span="16" class="endalign fontsize15">
                    <span>购物车占用:</span>
                  </el-col>
                  <el-col :span="8" class="leftalign fontsize15">
                    <span class="text-color">{{ scope.row.carts_num }}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="5">
                  <el-col :span="16" class="endalign fontsize15">
                    <span>订单占用:</span>
                  </el-col>
                  <el-col :span="8" class="leftalign fontsize15">
                    <span class="text-color">{{ scope.row.order_num }}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="5">
                  <el-col :span="16" class="endalign fontsize15">
                    <span>可用库存:</span>
                  </el-col>
                  <el-col :span="8" class="leftalign fontsize15">
                    <span class="text-color">{{ scope.row.us_num }}</span>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>

            <el-table-column
              prop="unarrived_stock_num"
              align="center"
              label="未到量"
            >
            </el-table-column>
          </el-table>
        </div>
        <div ref="listdata-footer" class="listdata-footer">
          <el-pagination
            :key="1"
            :current-page="pageInfo.page"
            :page-size="pageInfo.pagesize"
            :page-sizes="[10, 20, 50, 100, 200]"
            layout="total,sizes, prev, pager, next, jumper"
            :total="pageInfo.total"
            @size-change="handleRenewRenewSizeChange"
            @current-change="handleChangeCurrentPage"
            background
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import notArrive from "./js/index";
  
  export default notArrive;
  </script>
  
  <style scoped lang="scss">
  @import "css/index";
  </style>

  